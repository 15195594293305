@import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');



*
{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}


:root
{
  --top-z-index: 10000;
  --second-z-index: 1000;
  --third-z-index: 100;
  --fourth-z-index: 10;
  --fifth-z-index: 1;
  --lowest-z-idex: -1;

  /* Colors */
  --main-blue: rgb(74, 175, 234);
  --header-color: rgb(13, 116, 219);
  --main-white: rgb(245, 245, 245);
  --main-gray: rgba(230, 230, 230, 0.192);
  --explore-btn-color: rgb(137, 137, 167);
  --footer-blue: rgb(32, 115, 198);
  --img-background-color: rgb(225, 225, 225);


  /* NavBar */
  --navbar-height: 120px;
  --navbar-background-color: rgba(227, 225, 225, 0.5);
  --navbar-link-text-color: rgb(57, 54, 54);
  --navbar-link-font-weight: bold;
  --navbar-link-font-size: clamp(1.25rem, 4vw, 1.5rem);
  --font-type: "Times New Roman", Times, serif;

  /* Images */
  --img-box-shadow: 10px 10px 10px rgba(0,0,0, 0.25);

  /* Intro Image */
  --intro-background-gradient: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(0, 0, 0, 0.4688725490196079) 100%);

  /* Intro Paragraph */
  --intro-p-box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.25), inset -10px -10px 10px rgba(0, 0, 0, 0.1);
  --intro-p-box-shadow-mobile: inset 5px 5px 5px rgba(0, 0, 0, 0.25), inset -5px -5px 5px rgba(0, 0, 0, 0.1);
}


html
{
  width: 100%;
}



/****************************************************************************************/
/************************************** General *****************************************/
/****************************************************************************************/
body 
{
  margin: 0px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
}


code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a
{
  list-style: none;
  text-decoration: none;
  color: inherit;
}


.blue-text
{
  color: var(--footer-blue);
}


.bold-text
{
  font-weight: bold;
}


.general-info a 
{
  text-decoration: underline;
}


.general-info a:hover
{
  color: #ff0000;
}


.topic-container
{
  width: 100%;
  padding: clamp(20px, 5vw, 40px);

  font-family: var(--font-type);
}


.topic-container p
{
  font-size: clamp(1.15rem, 5vw, 1.35rem);
  padding: 5px;
  word-wrap: break-word;
  white-space: pre-line;
}


/****************************************************************************************/
/************************************** Navbar ******************************************/
/****************************************************************************************/
.navbar
{
  box-sizing: border-box;
  max-width: 100vw;
  width: 100%;
  height: var(--navbar-height);
  padding: 10px;

  position: fixed;
  z-index: var(--top-z-index);
  top: 0px;
  left: 0px;

  background-color: var(--navbar-background-color);
  border-bottom: solid 1px rgb(0, 0, 0);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);

  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: minmax(75px, 100px) auto;
}


.navbar-center
{
  width: 100%;
}


.navbar-logo
{
  height: 100%;
}


.navbar-logo p
{
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(255, 255, 255);
  color: #000000;
  font-size: clamp(1.2rem, 4vw, 1.75rem);
  font-weight: bold;
  font-family: var(--font-type);
  text-align: center;

  border: double 4px #000000;
}


.navbar-links
{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  background-color: transparent;
}


.navbar-link
{
  width: 100px;
  background-color: rgb(255, 255, 255);

  text-align: center;
  color: var(--navbar-link-text-color);
  font-family: var(--font-type);
  font-weight: var(--navbar-link-font-weight);
  font-size: var(--navbar-link-font-size);

  box-shadow:  3px 3px 3px rgba(0, 0, 0, 0.5);
  padding: 3px;
  border: solid 2px black;
}


.navbar-link:hover
{
  background-color: rgb(220, 220, 220);
}


.navbar-toggle-btn
{
  display: none;
}


.navbar-dropdown-links
{
  display: none;
}





/****************************************************************************************/
/************************************* HomePage *****************************************/
/****************************************************************************************/
.homepage-main
{
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center; 
  align-items: center;

  background-image: url(./../public/photos/img_1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.homepage-main::before
{
  content: '';
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;

  background-image: url(./../public/photos/HomePage/background_img_1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: screen;
}


.homepage-img
{
  aspect-ratio: 4/3;
  width: 100%;
}


.homepage-main-center
{
  width: clamp(250px, 35vw, 425px);
  height: clamp(250px, 35vw, 425px);
  margin-top: 20px;
  padding: 5px;

  background-color: none;
  border: solid 4px rgba(251, 246, 246, 0.605);
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}


.homepage-main-title
{
  font-size: clamp(1.5rem, 5vw, 4rem);
  font-weight: bold;
  color: #fefcfc;
  text-shadow: 15px 5px 5px rgba(0, 0, 0, 0.75);
  mix-blend-mode:hard-light;
}






/****************************************************************************************/
/************************************* Services *****************************************/
/****************************************************************************************/
.services
{
  width: 100%;
  height: 100%;
  
  background-color: var(--main-gray);
}


.services-title
{
  text-align: center;
  padding-block: clamp(20px, 3vw, 30px);

  font-size: clamp(1.75rem, 4vw, 2rem);
  font-family: var(--font-type);
  font-weight: bold;
}


.services-center
{
  width: auto;
  height: auto;
  background-color: inherit;

  padding-block: 20px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  row-gap: 25px;
  column-gap: 25px;
  grid-template-columns: minmax(150px, 400px) minmax(150px, 400px);
  grid-auto-rows: 450px;
  grid-template-areas: 'destinations food'
                        'hikes stores';
}


.services-article
{
  height: 100%;
  width: 100%;
  text-align: center;
}


.services-article-info
{
  height: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
}


.services-article-info h4
{
  font-size: clamp(1.5rem, 4vw, 2rem);
  font-family: var(--font-type);
  color: var(--header-color);
}


.services-article-img 
{
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;
}


.services-article-img:hover 
{
  box-shadow: var(--img-box-shadow);
}


.services-btn
{
  width: 100px;
  padding-block: 3px;
  border: none;
  background-color: var(--explore-btn-color);
  
  color: white;
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(1rem, 5vw, 1.25rem);
}


.services-btn:hover
{
  background-color: rgb(220, 220, 220);
}


#destinations-article 
{
  grid-area: destinations;
}


#food-article 
{
  grid-area: food;
}


#hikes-article
{
  grid-area: hikes
}


#stores-article 
{
  grid-area: stores;
}


/****************************************************************************************/
/************************************** Regions *****************************************/
/****************************************************************************************/
.regions-section
{
  background-color: white;
}


.page-main
{
  width: 100%;
  margin-block: var(--navbar-height);
  padding-block: 20px;
}


/* .regions-title
{
  height: 50px;
  width: 100%;
  background-color: var(--footer-blue);
} */


.regions-main-center
{
  width: 100%;
  height: auto;
  padding-block: clamp(15px, 10vw, 45px);

  display: grid;
  justify-items: center;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));

  column-gap: clamp(10px, 5vw, 20px);
  row-gap: clamp(20px, 5vw, 40px);
}


.region-article
{  
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  padding-bottom: 20px;
}


.region-article-title
{
  display: block;

  color: var(--header-color);
  font-family: var(--font-type);
  font-size: clamp(1.25rem, 5vw, 1.75rem);
  font-weight: bold;
  text-align: center;
}


.region-article-img
{
  margin-bottom: 5px;
  padding: 0px;
  width: clamp(200px, 30vw, 400px);
  height: clamp(200px, 30vw, 400px);
}


.region-article-img:hover
{
  box-shadow: var(--img-box-shadow);
}


.region-article-btn
{
  width: 100px;
  padding-block: 5px;

  color: rgb(255, 253, 253);
  background-color: var(--explore-btn-color);
  border: none;

  font-size: 1.2rem;
  font-weight: bold;
  font-family: var(--font-type);
}


.region-article-btn:hover
{
  background-color: rgb(35, 175, 180);
}


/****************************************************************************************/
/*************************************** Country ****************************************/
/****************************************************************************************/

.country-section
{
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin-bottom: 0px;

  background-repeat: none;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
}


.country-title
{
  color: white;
  font-family: var(--font-type);
  font-size: clamp(4rem, 10vw, 12rem);
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}


.country-info
{
  width: 100%;
  min-height: auto;
  padding: 20px 20px;

  background: linear-gradient(180deg, rgba(251, 248, 248, 0) 0%, rgba(197, 228, 255, 1) 100%);
  font-family: var(--font-type);
}


.country-info-title
{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;

  color: var(--header-color);
  font-size: 3rem;
  text-align: left;
}


.country-info-list
{
  font-size: 1.2rem;
  list-style: none;
}


.country-info-list li
{
  margin-bottom: 5px;
}


.country-info-list span
{
  font-weight: bold;
}


.country-info-list a
{
  text-decoration: underline;
}


.country-info-list a:hover
{
  color:purple;
}


.country-tidbits-section
{
 width: 100%;
 min-height: auto;
 padding: 20px 10px;
 margin: 0px;
}


.country-tidbits-title
{
  color: var(--header-color);
  font-family: var(--font-type);
  font-size: 3rem;
  text-align: center;
  margin-right: 20px;
}


.country-tidbits-container
{
  width: 50%;
  min-height: auto;
  background-color: rgba(197, 228, 255, 1);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.country-tidibits-inner-container
{
  background-color: rgba(197, 228, 255, 1);
  border: solid 2px rgb(176, 176, 176);
  padding: 15px;
}


.country-tidbits
{
  width: 100%;
  height: auto;
  padding: 10px;

  font-size: 1.25rem;
  font-family: var(--font-type);
}


/****************************************************************************************/
/************************************* Destinations *************************************/
/****************************************************************************************/
.destinations-page
{
  margin-top: var(--navbar-height);
  width: 100%;
  min-height: 100vh;
}


.destination-articles-container
{
  width: 100%;
  height: auto;
  padding-block: clamp(15px, 5vw, 25px);

  display: grid;
  row-gap: clamp(15px, 5vw, 25px);
  column-gap: clamp(15px, 5vw, 25px);
  justify-content: center;
  align-items: start;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-auto-rows: 450px;
}


.destination-link
{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* row-gap: 5px; */
}


.destination-article-img
{
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;
}


.destination-article-img:hover
{
  box-shadow: var(--img-box-shadow);
}


.destination-link-title
{
  width: 95%;

  color: var(--header-color);
  text-align: center;
  font-family: var(--font-type);
  font-weight: bold;
  font-size: 2rem;
}


.destination-link-desc
{
  width: 95%;
  max-width: 400px;
  text-align: center;
}

.destination-section
{
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "destination-img destination-img destination-img destination-img"
                       "destination-main destination-main destination-main destination-sidebar";
}


.destination-img-div
{
  width: 100%;
  height: 100vh;
  grid-area: destination-img;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.destination-img-title
{
  color: white;
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(4rem, 10vw, 12rem);
  text-shadow: 5px 3px 2px rgba(0, 0, 0, 0.75);
  text-align: center;
}


.destination-main
{
  grid-area: destination-main;
  width: 100%;
  padding: clamp(20px, 5vw, 40px);

  display: flex;
  flex-direction: column;
  align-items: center;
}


#destination-sidebar
{
  grid-area: destination-sidebar;
  min-width: 100px;
  width: 100%;
  min-height: 100vh;
  background-color: transparent;
}


.destination-intro-paragraph
{
  background-color: rgba(103, 188, 248, 0.365);
  width: 95%;
  padding: clamp(3px, 5vw, 15px);
  box-shadow: var(--intro-p-box-shadow);
}


.desination-topic-container
{
  margin-top: clamp(10px, 5vw, 20px);
  
}


.destination-topic
{
  width: 100%;
  margin-inline: auto;
  margin-bottom: 75px;

  display: flex;
  flex-direction: column;
  row-gap: 25px;
  
  /* align-items: center; */
}


.destination-topic-header
{
  color: var(--header-color);
  font-family: var(--font-type);
  font-size: clamp(2rem, 6vw, 3rem);
  font-weight: bold;
}


.destination-topic-img
{
  width: 100%;
  align-self: center;
  aspect-ratio: 3/2;

  padding: clamp(5px, 2vw, 15px);
  background-color: var(--img-background-color);
}


.destination-topic-paragraph
{
  padding: 0px;
}


.destination-main p
{
  font-family: var(--font-type);
  font-size: clamp(1rem, 5vw, 1.2rem);

  word-wrap: break-word;
  white-space: pre-line;
}



/****************************************************************************************/
/***************************************** Food *****************************************/
/****************************************************************************************/
.food-section
{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.food-page-main-center
{
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: var(--navbar-height);

  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-template-rows: 450px;
  column-gap: clamp(10px, 5vw, 20px);
  row-gap: clamp(10px, 5vw, 20px);
  justify-content: center;
}


.food-link
{
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}


.food-link-img 
{
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;
}

.food-link-img:hover
{
  box-shadow: var(--img-box-shadow);
}


.food-link-info 
{
  padding: 10px;
}


.food-link-title
{
  width: 100%; 
  display: inline-block;

  text-align: center;
  color:rgb(3, 81, 183);
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  font-weight: bold;
  font-family: var(--font-type);
}


.food-link-desc
{
  text-align: center;
  color: rgb(57, 57, 57);
}


.restaurant-header
{
  position: relative;
  width: 100%;
  height: 100vh;

  background-repeat: none;
  background-size: cover;
  background-position: center;
}


.restaurant-header-modal
{
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  background: var(--intro-background-gradient);

  display: flex;
  justify-content: center;
  align-items: center;
}


.restaurant-header-title
{
  width: 90%;
  color: white;
  font-family: var(--font-type);
  font-size: clamp(4rem, 10vw, 12rem);
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}


.restaurant-main-section
{
  display: grid;
  grid-template-columns: 75% 25%;
}


.restaurant-intro-paragraph
{
  display: relative;
  width: 90%;
  margin: clamp(20px, 5vw, 40px) auto;
  padding: clamp(3px, 5vw, 15px);
  background-color: rgb(253, 195, 195);

  font-family: var(--font-type);
  font-size: clamp(1.1rem, 5vw, 1.3rem);

  box-shadow: var(--intro-p-box-shadow);
}


#restaurant-sidebar
{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: transparent;
}


/****************************************************************************************/
/************************************* List Article *************************************/
/****************************************************************************************/
.list-article
{
  width: 100%;
  min-height: 100vh;
  height: auto;
  /* margin-block: var(--navbar-height); */
  margin-top: var(--navbar-height);
}


.list-article-img-div
{
  width: 100%;
  height: auto;
  position: relative;
}


.list-article-main-img
{
  width: 100%;
}


.list-article-modal
{
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(8, 19, 25, 0.8688725490196079) 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.list-article-modal-title
{
  top: clamp(10px, 5vw, 20px);
  
  display: block;
  max-width: 100%;


  text-align: center;
  font-family: var(--font-type);
  font-size: clamp(1.5rem, 5vw, 4rem);
  color: white;
}


.list-article-modal-desc
{
  display: inline-block;
  max-width: 75%;

  justify-self: center;

  text-align: center;
  font-family: var(--font-type);
  font-size: clamp(1rem, 5vw, 1.5rem);
  color: white;
}


.article-content-container
{
  display: grid;
  grid-template-columns: 80% 20%;
}


.list-article-main
{
  width: 100%;
  height: auto;
  padding-block: clamp(20px, 5vw, 40px);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: clamp(20px, 5vw, 30px);
}


.list-item
{
  width: 85%;
  height: auto;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
}


.list-item-name
{
  font-family: var(--font-type);
  font-size: clamp(1.25rem, 5vw, 2rem);
  font-weight: bold;
  color: rgb(0, 0, 0);
}


.list-item-img
{
  width: 100%;
  aspect-ratio: 3/2;
  padding: clamp(5px, 2vw, 15px);
  background-color: var(--img-background-color);
}


.list-item-location
{
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(1rem, 5vw, 1.25rem);
  line-height: 1.25;
  color: var(--header-color);
}


.list-item-paragraph
{
  word-wrap: break-word;
  white-space: pre-line;
}


.article-sidebar
{
  width: 100%;
  background-color: rgba(76, 0, 130, 0.484);
  min-height: 100px;
}

/****************************************************************************************/
/************************************** About Section ***********************************/
/****************************************************************************************/
.about-section
{
  width: 100%;
  height: 100%;

  margin-block: var(--navbar-height);
}


.about-intro
{
  width: 100%;
  height: auto;
  padding-top: clamp(10px, 5vw, 25px);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'about-intro-desc about-image';
  background-color: inherit;
}


.about-main-img
{
  max-width: 100%;
  grid-area: about-image;
}


.about-intro-desc
{
  grid-area: about-intro-desc;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  border-top: solid 4px #000000;
  padding-top: 10px;
  
  font-family: var(--font-type);
  font-size: 1.1rem;

  word-wrap: break-word;
  white-space: pre-line;
}


.about-info-title
{
  display: block;
  width: 100%;
  margin-top: clamp(5px, 2vw, 10px);

  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(1.25rem, 10vw, 3rem);
  text-align: center;
  color: var(--header-color);
}

/****************************************************************************************/
/*********************************** Budget Calc. ***************************************/
/****************************************************************************************/
.budget-calc
{
  min-height: 100vh;
  width: 100%;
  margin-top: calc(var(--navbar-height) + 20px);
}


.budget-calc-title
{
  margin-bottom: 20px;

  color: var(--header-color);
  text-align: center;
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(2.5rem, 7vw, 4rem);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}


.budget-calc-main
{
  width: 100%;
  padding: 15px;
  /* background-color: red; */
  display: grid;
  grid-template-columns: 60% 40%;
}


.budget-calc-form
{
  width: 100%;
  min-height: auto;
  padding: 15px;
  /* background-color: rgb(179, 140, 179); */
  background-color: rgb(199, 226, 248);

  display: flex;
  flex-direction: column;
}


.budget-field-title
{
  margin-bottom: 10px;
  /* color:rgb(7, 146, 205); */
  color: rgb(7, 124, 174);
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(1.75rem, 7vw, 2rem);
  border-bottom: solid 2px black;
}


.budget-field
{
  margin-bottom: clamp(15px, 5vw, 25px);
  /* background-color: rgb(199, 226, 248); */
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
}


.budget-form-label
{
  font-family: var(--font-type);
  font-size: clamp(1rem, 5vw, 1.25rem);
}


.budget-form-input
{
  max-width: 200px;
  font-family: var(--font-type);
  font-size: 1.25rem;
  padding: 3px;
}


.budget-form-btn
{
  width: 50%;
  min-width: 150px;
  padding-block: 3px;
  align-self: end;

  border: none;
  background-color: var(--header-color);
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
}


.budget-form-btn:hover
{
  background-color: rgb(192, 192, 193);
}


.breakdown-sheet
{
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
}


.budget-breakdown
{
  width: 90%;
  min-height: 100px;
  height: auto;
  padding-block: 10px;
  background-color: lightgrey;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}


.budget-breakdown-title
{
  font-family: var(--font-type);
  font-weight: bold;
  font-size: 1.5rem;
}


.budget-breakdown-number 
{
  color: var(--header-color);
  font-family: var(--font-type);
  font-weight: bold;
  font-size: 2.5rem;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.25);
}


.line-break-span
{
  margin-block: 15px;
  display: block;
  content: "";
  width: 95%;
  height: 2px;
  background-color: var(--footer-blue);
}


.error-message-div
{
  position: fixed;
  top: calc(var(--navbar-height) + 5px);
  left: calc(50% - 125px);

  width: 250px;
  max-width: 95%;
  height: auto;
  min-height: 50px;

  border-radius: 0.5rem;
  padding: 10px;
  background-color: #d3d3d3;
  
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgb(234, 7, 7);
  font-family: var(--font-type);
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}


.display-none
{
  display: none;
}


.months-form
{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}


.months-form-btn
{
  width: 100%;
  padding: 3px;
  border: none;
  background-color: var(--header-color);

  color: white;
  font-family: var(--font-type);
  font-weight: bold;
  font-size: 1rem;
}


.months-form-btn:hover 
{
  background-color: rgb(192, 192, 193);
}



/****************************************************************************************/
/*************************************** Hikes ******************************************/
/****************************************************************************************/
.hikes-page-section
{
  min-height: 100vh;
}


.hikes-page-intro
{
  height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
}


.hiking-links-container
{
  width: 100%;
  margin-block: clamp(20px, 5vw, 40px);

  display: grid;
  row-gap: clamp(15px, 5vw, 25px);
  column-gap: clamp(15px, 5vw, 25px);
  justify-content: center;
  justify-items: center;
  align-items: start;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-template-rows: 450px;
}


.hiking-link
{
  width: 100%;
  max-width: 350px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  row-gap: 5px;
}


.hiking-link-img
{
  width: 100%;
  max-width: 300px;

  aspect-ratio: 1/1;
}


.hikinglink-img:hover 
{
  box-shadow: var(--img-box-shadow);
}


.hiking-link-title
{
  color: var(--header-color);
  font-family: var(--font-type);
  font-weight: bold;
  font-size: clamp(1.5rem, 5vw, 1.75rem);
}


.hiking-link-desc
{
  text-align: center;
}


.hiking-difficulty
{
  width: 100px;
  padding: 3px 10px;
  border-radius: .75rem;

  display: flex;
  justify-content: center;
  align-items: center;
  line-height: auto;

  color: rgb(255, 255, 255);
  font-family: var(--font-type);
  font-weight: bold;
  
  text-transform: capitalize;
}


.easy-difficulty
{
  background-color: #008000;
}


.moderate-difficulty 
{
  background-color: #ffa500;
}


.hard-difficulty
{
  background-color: #ff0000;
}


.hike-section-intro
{
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: relative;
}


.hike-intro-modal
{
  position: absolute;
  top: 0px;
  left: 0px;
  
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(0, 0, 0, 0.469) 100%);

  display: flex;
  justify-content: center;
  align-items: center;
}


.hike-header-title
{
  width: 90%;
  color: white;
  font-family: var(--font-type);
  font-size: clamp(4rem, 10vw, 12rem);
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}


.hike-section
{
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-areas: 'hike-main hike-sidebar';
  grid-template-rows: 1fr;
}


.hike-main
{
  grid-area: hike-main;
}


.hike-intro-paragraph
{
  display: relative;
  width: 90%;
  margin: clamp(20px, 5vw, 40px) auto;
  padding: clamp(3px, 5vw, 15px);
  background-color: rgb(226, 247, 212);

  font-family: var(--font-type);
  font-size: clamp(1.1rem, 5vw, 1.3rem);

  box-shadow: var(--intro-p-box-shadow);
}


.general-info
{
  width: 100%;
  padding: clamp(15px, 5vw, 30px);
  list-style-type: none;
  font-family: var(--font-type);
  background: linear-gradient(180deg, rgba(251, 248, 248, 0) 0%, rgba(197, 228, 255, 1) 100%);
}


.gen-info-li
{
  font-size: clamp(1.2rem, 5vw, 1.3rem);
  margin-bottom: 5px;
}


.gen-info-li .blue-text
{
  font-weight: bold;
}




#hike-sidebar
{
  grid-area: hike-sidebar;
  min-width: 100px;
  width: 100%;
  min-height: 100vh;
  background-color: transparent;
}


/****************************************************************************************/
/************************************** Footer ******************************************/
/****************************************************************************************/
.footer
{
  width: 100%;
  min-height: 100px;

  background-color: var(--footer-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer-text
{
  color: white;
  font-family: var(--font-type);
  font-size: 1.25rem;
}





/****************************************************************************************/
/*********************************** Media Queries **************************************/
/****************************************************************************************/
@media screen  and (max-width: 750px)
{

  .topic-container 
  {
    padding: 0px;
  }


  .topic-container p 
  {
    font-size: clamp(1.15rem, 5vw, 1.35rem);
    padding: 10px;
    text-align: center;
  }

  .homepage-img 
  {
    aspect-ratio: 3/4;
    height: 100%;
  }


  .navbar-center
  {
    /* width: 100px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }


  .navbar-links
  {
    display: none;
  }

  .navbar-toggle-btn
  {
    display: block;
    
    background-color: transparent;
    border: none;

    color: rgba(0, 0, 0);
    font-size: 1.5rem;
    font-weight: bold;
  }


  .navbar-dropdown-links 
  {
    height: 0px;
    width: 100vw;
    padding-block: 0px;
    background-color: var(--navbar-background-color);
    border-bottom: solid 2px rgb(0, 0, 0);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: var(--navbar-height);
    left: 0px;
    transition: height 500ms linear;
    overflow: hidden;
  }


  .show-navbar-dropdown-links 
  {
    height: 100px;
    background-color: rgba(255, 255, 255, 0.5);
  }


  .dropdown-links 
  {
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    color: white;
  }

    

/****************************************************************************************/
/************************************* Services *****************************************/
/****************************************************************************************/


  .services-center
  {
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: 100%;
    row-gap: 15px;
    column-gap: none;
    grid-auto-rows: 510px;
    grid-template-areas: 'destinations'
                         'food'
                         'hikes'
                         'stores';
  }

  .services-article
  {
    height: 100%;
  }

  .sercices-article-img
  {
    width: 280px;
    height: 300px;
  }

  .services-article-info
  {
    height: auto;
    padding-block: 5px;
    justify-content: center;
  }


  .services-article-img
  {
    width: 100%;
    max-width: 400px;
  }


  .services-article-info p
  {
    padding-inline: 15px;
  }


  .food-page-main-center
  {
    grid-template-columns: 1fr;
  }



  /****************************************************************************************/
  /************************************* Articles *****************************************/
  /****************************************************************************************/
  .list-article-modal-desc
  {
    margin-top: 5px;
    max-width: 95%;
  }
  
  .article-content-container
  {
    grid-template-columns: 1fr;
  }


  .list-item
  {
    width: 90%;
  }

  .article-sidebar 
  {
    background-color: rgba(255, 255, 255, 0.158);
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: var(--second-z-index);
  }


  /****************************************************************************************/
  /***************************************** Food *****************************************/
  /****************************************************************************************/
  .restaurant-main-section
  {
    grid-template-columns: 100%;
  }



  #restaurant-sidebar
  {
    height: auto;
    min-height: 100px;
  }
  /****************************************************************************************/
  /*************************************** Country ****************************************/
  /****************************************************************************************/
  .country-tidbits-container
  {
    width: 95%;
    margin-inline: auto;
  }


  /****************************************************************************************/
  /************************************* Destinations *************************************/
  /****************************************************************************************/
  .destination-articles-container
  {
    grid-template-columns: 1fr;
    padding: 0px;
    padding-bottom: 15px;
  }
  
  
  .destination-link
  {
    width: 100%;
  }
  

  .destination-article-img 
  {
    width: 100%;
    max-width: 400px;
    height: 300px;
  }


  .destination-intro-paragraph
  {
    box-shadow: var(--intro-p-box-shadow-mobile);
  }


  .destination-section 
  {
    display:block ;
  }


  .destination-topic-header
  {
    text-align: center;
  }


  #destination-sidebar
  {
    min-height: 100px;
    background-color: transparent;
  }


  .destination-main
  {
    padding-inline: 0px;
  }


  .destination-topic-img 
  {
    width: 100%;
    background: none;
    padding: 0px;
    align-self: center;
    aspect-ratio: 1/1;
  }


  .destination-topic-paragraph 
  {
    padding-inline: 15px;
  }

  /****************************************************************************************/
  /*********************************** Budget Calc. ***************************************/
  /****************************************************************************************/
  .budget-calc-main
  {
    grid-template-columns: 100%;
  }


  .budget-calc-form
  {
    grid-template-columns: 1fr 1fr; 
    padding: 8px;
  }


  .budget-form-input
  {
    width: 150px;
  }


  /****************************************************************************************/
  /*************************************** Hikes ******************************************/
  /****************************************************************************************/
  .hiking-links-container
  {
    grid-template-columns: 1fr;
  }


  .hiking-link-img
  {
    width: 100%;
  }

  .hiking-link-desc
  {
    padding-inline: 5px;
  }


  .hike-intro-paragraph
  {
    box-shadow: var(--intro-p-box-shadow-mobile);
  }


  .hike-section 
  {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: 'hike-main'
                         'hike-sidebar';
    grid-template-rows: 1fr;
  }


  #hike-sidebar
  {
    height: auto;
    min-height: 100px;
  }

  




  /****************************************************************************************/
  /************************************** About Section ***********************************/
  /****************************************************************************************/
  .about-intro
  {
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-areas: 'about-image' 
                         'about-intro-desc';
  }
  .about-main-img
  {
    width: 100%;
  }

    
}

